import React from 'react';
import { Translate } from 'react-redux-i18n';
const Dashboard = React.lazy(() => import('./views/Dashboard'));
const ConfigDynamicForm = React.lazy(() => import('./views/ConfigDynamicForm'));
const RedirectConfig = React.lazy(() => import('./views/RedirectConfig'));
const HotspotGatewayStatus = React.lazy(() => import('./views/HotspotGatewayStatus/HotspotGatewayStatus'));
const Options = React.lazy(() => import('./views/Options/Options'));
const ChangePassword = React.lazy(() => import('./views/ChangePassword'));
const CardGenerate = React.lazy(() => import('./views/CardGenerate'));
const CardManager = React.lazy(() => import('./views/CardManager'));
const ChangeTheme = React.lazy(() => import('./views/ChangeTheme'));
const SocialReport = React.lazy(() => import('./views/SocialReport'));
const LoginReport = React.lazy(() => import('./views/LoginReport'));
const AdvertisementReport = React.lazy(() => import('./views/AdvertisementReport'));
const CustgroupList = React.lazy(() => import('./views/CustgroupList'));
const Log = React.lazy(() => import('./views/Log'));
const UserManagement = React.lazy(() => import('./views/UserManagement'));
const AdvertisementManagement = React.lazy(() => import('./views/AdvertisementManagement'));
const Packages = React.lazy(() => import('./views/Packages'));
const OnlineUsers = React.lazy(() => import('./views/OnlineUsers'));
const MemberManager = React.lazy(() => import('./views/MemberManager'));
const RevenueReport = React.lazy(() => import('./views/RevenueReport'));
const CardStock = React.lazy(() => import('./views/CardStock'));

const VersionHistory = React.lazy(() => import('./views/VersionHistory'));
const Occupation = React.lazy(() => import('./views/Occupation'));
const RoleManagement = React.lazy(() => import('./views/RoleManagement'));


const routes = [
  { path: '/dashboard', exact: true, name: <><i className="nav-icon icon-speedometer" /> <Translate value="dashboard"/></>, component: Dashboard,code:'dashboard' },
  { path: '/config-register-form', exact: true, name: <><i className="nav-icon fa fa-check-square-o" /> <Translate value="config_register_form"/></>, component: ConfigDynamicForm ,code:'config-register-form'},
  { path: '/redirect-config', name: <><i className="nav-icon fa fa-link" /> <Translate value="redirect_config"/></>, component: RedirectConfig,code:'redirect-config' },
  { path: '/hotspot-gateway-status', name: <><i className="nav-icon fa fa-rss" /> <Translate value="hotspot_gateway"/></>, component: HotspotGatewayStatus,code:'hotspot-gateway-status' },
  { path: '/options', name: <><i className="nav-icon fa fa-cog" /> <Translate value="options"/></>, component: Options,code:'options' },
  { path: '/change-password', name: <><i className="nav-icon fa fa-lock" /> <Translate value="changePassword"/>  </>, component: ChangePassword,code:'change-password' },
  { path: '/card-generate', name: <><i className="nav-icon fa fa-credit-card" /> <Translate value="card_gen" /></>, component: CardGenerate ,code:'card-generate' },
  { path: '/card-manager', name: <><i className="nav-icon fa fa-credit-card" /> <Translate value="card_manager" /></>, component: CardManager ,code:'card-manager' },
  { path: '/change-theme', name: <><i className="nav-icon fa fa-picture-o" /> <Translate value="changeTheme"/>  </>, component: ChangeTheme,code:'change-theme' },
  { path: '/custgroup-list', name: <><i className="" />   </>, component: CustgroupList ,code:'custgroup-list' },
  // { path: '/social-report', name: <><i className="nav-icon  fa fa-pie-chart" />   <Translate value="socialReport"/>  </>, component: SocialReport ,code:'social-report' },
  { path: '/role-management', name: <><i className="nav-icon  fa fa-users" />   <Translate value="role_management"/> </>, component: RoleManagement ,code:'role-management' },
  { path: '/user-management', name: <><i className="nav-icon  fa fa-users" />   <Translate value="user_management"/> </>, component: UserManagement ,code:'user-management' },

  { path: '/log', name: <><i className="nav-icon fa fa-gavel" />   <Translate value="log"/> </>, component: Log ,code:'log' },
  { path: '/login-report', name: <><i className="nav-icon fa fa-pie-chart" /> <Translate value="login-report"/></>, component: LoginReport,code:'login-report' },
  { path: '/advertisement-management', name: <><i className="nav-icon fa fa-bullhorn" /> <Translate value="advertisement-management"/></>, component: AdvertisementManagement,code:'advertisement-management' },
  { path: '/internet-packages', name: <><i className="fa fa-archive" /> <Translate value="package"/></>, component: Packages, code: 'internet-packages'},
  { path: '/online-users', name: <><i className="fa fa-user" /> <Translate value="online-user"/></>, component: OnlineUsers, code: 'online-users'},
  { path: '/member-manager', name: <><i className="fa fa-briefcase" /> <Translate value="member-manager"/></>, component: MemberManager, code: 'member-manager'},
  { path: '/revenue-report', name: <><i className="nav-icon fa fa-pie-chart" /> <Translate value="revenue-report"/></>, component: RevenueReport, code: 'revenue-report'},
  { path: '/card-stock', name: <><i className="nav-icon fa fa-credit-card" /> <Translate value="card-stock"/></>, component: CardStock, code: 'card-stock'},

  { path: '/occupation', name: <><i className="nav-icon fa fa-briefcase" /> <Translate value="occupation"/></>, component: Occupation, code: 'occupation'},
  { path: '/version-history', name: <><i className="nav-icon fa fa-history" /> <Translate value="version_history"/></>, component: VersionHistory, code: 'version-history'},
  { path: '/advertisement-report', name: <><i className="nav-icon fa fa-pie-chart" /> <Translate value="advertisement_report"/></>, component: AdvertisementReport, code: 'advertisement-report'}
];

export default routes;
