import { menuConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from '.';
// import { history } from '../_helpers';
import Notification  from '../_services/notification.service';
export const menuActions = {
  getMenu,
};
function getMenu(data) {
  // console.log("getMenu",data)
    return dispatch => {
        // dispatch(request({ [] }));

        userService.permissionLogin(data)
            .then(
              res => {
                console.log(res)
                if(res.resultCode === "20000" ){
                  dispatch(success(res.resultData,1 ));
                }else{
                  dispatch(failure(true));
                  // dispatch(alertActions.error(res));
                  Notification.error({
                    message:"",
                    description:res.errorMessage,
                    resultCode:res.resultCode
                  });
                }
                },
                error => {
                  console.log(error)
                    Notification.error({
                      message:"",
                      description: "",
                    });
                    dispatch(failure(true));
                    // dispatch(alertActions.error(error));
                }
            );
    };

    function request(menu) { return { type: menuConstants.MENU_REQUEST, menu } }
    function success(menu,id) { return { type: menuConstants.MENU_SUCCESS, menu:menu,id:id } }
    function failure(error){ return { type: menuConstants.MENU_FAILURE, error } }
}
