// import AppConfig  from '../services/app.config';
import { Modal, Button } from 'antd';
import React  from 'react';
const { confirm } = Modal;
class Notification {

  constructor() {

  }
  error(data){
    let { message , btn , key ,description='ไม่สามารถเชื่อมต่อระบบได้ กรุณาติดต่อ Callcenter', placement ='',duration} = data
    let languageCode = 'EN'
    try {
      var haslocalStorage = !!localStorage;
        if (haslocalStorage) {
        if(localStorage.getItem('locale')){
          languageCode = localStorage.getItem('locale') || 'EN'
        }
      }
    }
    catch (e) {

    }
    if(data.resultCode && data.resultCode !== "20000"){
      description = "<div>["+data.resultCode+"]</div>" + description
    }

    Modal.error({
      title: <div dangerouslySetInnerHTML={{__html:description}}></div>,
      okText: languageCode === 'EN'?'Close':'ปิด',
      centered:true,
      okButtonProps: {
      },
      onOk() {
        console.log('OK');
        if(data.onCancel){
          data.onCancel()
        }
      },
      onCancel() {
        console.log('Cancel');
        if(data.onCancel){
          data.onCancel()
        }

      },
    });
    return ;
  }

  success(data){
    let { message , btn , key ,description='ไม่สามารถเชื่อมต่อระบบได้ กรุณาติดต่อ Callcenter', placement ='',duration} = data
    let languageCode = 'EN'
    try {
      var haslocalStorage = !!localStorage;
        if (haslocalStorage) {
        if(localStorage.getItem('locale')){
          languageCode = localStorage.getItem('locale') || 'EN'
        }
      }
    }
    catch (e) {

    }

    Modal.success({
      title: <div dangerouslySetInnerHTML={{__html:description}}></div>,
      okText: languageCode === 'EN'?'Close':'ปิด',
      centered:true,
      okButtonProps: {
      },
      onOk() {
        console.log('OK');
        if(data.onCancel){
          data.onCancel()
        }
      },
      onCancel() {
        if(data.onCancel){
          data.onCancel()
        }
      },
    });
    return ;
  }

  open(){

  }
}


export default new Notification();
