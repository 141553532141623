import { userConstants,menuConstants,localeConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
import Notification  from '../_services/notification.service';
import {
  setLocale,
} from 'react-redux-i18n'
export const userActions = {
    login,
    logout,
    getAll,
    updateLocale,
    updateUser,
    updateUserData
};
function login(username, password , flag = 0) {
    return dispatch => {
        dispatch(request({ username }));
            userService.login(username, password , flag)
            .then(
              res => {
                  if(res.resultCode === "20000"&& res.resultData.accessToken){

                    // userService.custgroupList(res.resultData.accessToken) .then(
                    //   res2 => {
                    //     if(res2.resultCode === "20000" && res2.resultData && res2.resultData.length > 0){
                          let user = {
                            ...res.resultData,
                            // ...res2.resultData[0],
                            token:res.resultData.accessToken,
                            username:res.resultData.username,
                            flag:flag
                          }
                          console.log("user",user)
                          localStorage.setItem('user-data', JSON.stringify(user));
                          dispatch(successMenu([]));


                          dispatch(success(user));
                    //     }else{
                    //       Notification.error({
                    //         message:"",
                    //         description: res2.errorMessage,
                    //       });
                    //     }
                    //   },
                    //   error2 => {
                    //       Notification.error({
                    //         message:"",
                    //         description: "",
                    //       });
                    //       dispatch(failure(error2));
                    //       dispatch(alertActions.error(error2));
                    // })

                  }else{
                    Notification.error({
                      message:"",
                      description: res.errorMessage,
                      resultCode:res.resultCode
                    });
                    dispatch(failure(res));
                    dispatch(alertActions.error(res));
                  }
                },
                error => {
                  Notification.error({
                    message:"",
                    description: "",
                  });
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }

    function successMenu(menu) { return { type: menuConstants.MENU_SUCCESS, menu } }
}




function logout() {
  console.log("logout")
  // alert();

    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}




function updateLocale(locale = "EN") {
  return dispatch => {
    localStorage.setItem('locale', locale);
    dispatch(update(locale));
    dispatch(setLocale(locale));

  }



  // return dispatch => {};
  function update(data) {

    return { type: localeConstants.LOCAL_UPDATE, data }
  }
}


function updateUser(user) {
  return dispatch => {
    dispatch(success(user));
  }

  function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
}



function updateUserData(user,menu) {
  return dispatch => {
    dispatch(successmenu([],1 ));
    localStorage.setItem('user-data', JSON.stringify(user));
    setTimeout(() => {
      dispatch(success(user));
    }, 10);


  }
        function successmenu(menu,id) { return { type: menuConstants.MENU_SUCCESS, menu:menu,id:id } }
      function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
      function successMenu(menu) { return { type: menuConstants.MENU_SUCCESS, menu } }
}
