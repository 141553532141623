// import { Dictionary } from './Dictionary'

const TH = {
  "Logo Header":"โลโก้หัวข้อ",
  "Logo Form":"โลโก้กลาง",
  "logobuilding":"โลโก้ข้อมูลตึก",
  "refresh":"รีเฟรช",
  'dashboard':"แผงสรุปข้อมูล",
  'config_register_form': "ตั้งค่าฟอร์มลงทะเบียน",
  'redirect_config': "ตั้งค่าการเปลี่ยนเส้นทาง",
  'options': "ตัวเลือก",
  'hotspot_gateway': "สถานะ Alpha Box",
  'msg_connect': 'ขณะนี้ท่านกำลังเชื่อมต่ออยู่กับเครือข่ายของอาคาร สามารถติดต่อซื้อบัตรได้ที่สำนักงาน',
  'nav_information': 'ข้อมูลการใช้งาน',
  'nav_assistance': 'โปรแกรมช่วยเหลือ',
  'nav_discussion': 'แจ้งปัญหา/แนะนำบริการ',
  'msg_support_time': 'ตลอด 24 ชั่วโมง',
  'msg_keep_logged_in': 'จดจำชื่อผู้ใช้และรหัสผ่าน',
  'msg_accept': 'ยอมรับเงื่อนไข',
  'msg_term_of_use': 'ข้อตกลงการใช้งาน*',
  'msg_number_line': 'จำนวนสายที่มี',
  'msg_status_normal': 'ปกติ',
  'msg_status_error': 'ไม่ปกติ',
  'confirm_delete_advertisement_management': "โฆษณาที่ลบจะไม่แสดงสถิติในหน้ารายงานสถิติโฆษณา ท่านยืนยันที่จะลบโฆษณาใช่หรือไม่?",
  'confirm_delete': 'คุณต้องการจะลบใช่หรือไม่',
  'confirm_copy': 'คุณต้องการจะสำเนาใช่หรือไม่',
  'confirm_delete_yes': 'ยืนยัน',
  'confirm_delete_no': 'ยกเลิก',
  'click_other_logout': 'คลิกเพื่อให้ผู้ใช้อีกเครื่องออกจากระบบ',
  'buy_card': 'ซื้อบัตร',
  'read': 'อ่าน',
  "buildingPhone":"เบอร์โทรสถานที่",
  'vBuildingNameTH':'กรุณากรอกชื่อสถานที่(TH)',
  'vBuildingNameEN':'กรุณากรอกชื่อสถานที่(EN)',
  'vBuildingNameTH2':'กรุณากรอกชื่อสถานประกอบการ(TH)',
  'vBuildingNameEN2':'กรุณากรอกชื่อสถานประกอบการ(EN)',
  'none_building':'ไม่พบข้อมูลตึก',
  'errAgreement': 'ท่านต้องยอมรับข้อตกลงการใช้งาน ด้วยการติ๊กเครื่องหมายถูกที่ช่องสี่เหลี่ยมใต้ช่องกรอกรหัสผ่าน จึงจะเข้าสู่ระบบได้',
  'errBlankUserPass': 'โปรดกรอกชื่อผู้ใช้และรหัสผ่าน',
  'errUserPassIncorrect': 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
  'errInvalidCustGroup': 'บัตรของท่านได้ซื้อมาจากสถานที่อื่น บัตรจะสามารถถูกใช้งาน ณ สถานที่ที่ซื้อมาเท่านั้น',
  'errFreeTestDisabled': 'ไม่สามารถใช้ชื่อผู้ใช้สำหรับทดสอบฟรีที่นี่',
  'errActiveExpireDate': 'บัตรของท่านครบจำนวนวันที่อนุญาตให้ใช้งานแล้ว',
  'errCardExpireDate': 'บัตรของท่านหมดอายุแล้ว',
  'errSmartZoneFreetest': 'ไม่สามารถดูเวลาการใช้งานของชื่อผู้ใช้สำหรับทดสอบฟรี',
  'errLockMacInvalid': 'Username ของท่านไม่สามารถเข้าสู่ระบบจากเครื่องนี้',
  'errMacAlreadyUsedPromotion': 'เครื่องที่ท่านเชื่อมต่ออินเตอร์เน็ต ใช้สิทธิ์เล่นเน็ตฟรีไปแล้ว ** สามารถใช้ได้ 1 สิทธิ์ / 1 หมายเลขโทรศัพท์ / 1 เครื่องใช้งาน',
  'errPromotionUsernameForOtherMac': 'username ไม่สามารถใช้งานกับเครื่องนี้ได้ ** สามารถใช้ได้ 1 สิทธิ์ / 1 หมายเลขโทรศัพท์ / 1 เครื่องใช้งาน **',
  'errLoginData': 'ข้อมูลในการเข้าสู่ระบบไม่ครบถ้วน กรุณาคลิกลิงค์ด้านล่างแล้วลองใหม่อีกครั้ง',
  'errLoginToRMS': 'เกิดปัญหาการ Login กับ SmartRMS',
  'errRMSCardNotFound': 'ท่านยังไม่ได้ซื้อบัตร กรุณาซื้อบัตรก่อน',
  'errLoginFail': 'เข้าสู่ระบบผิดพลาดกรุณาลอกงใหม่อีกครั้ง',
  'errOther':'ไม่สามารถเชื่อมต่อระบบได้ กรุณาติดต่อ Callcenter',
  'errOldNewPasswordSame': "รหัสผ่านเก่าและรหัสผ่านใหม่เหมือนกัน",
  'errOldNewPasswordMin': "รหัสผ่านจะต้องมีความยาว 4-16 ตัวอักษร",
  'errMobiledMin': "เบอร์โทรต้องมีความยาว 10 ตัวอักษร",
  'errMobiledMax': "เบอร์โทรต้องมีความยาว 10 ตัวอักษร",
  "errOldNewPasswordRegex":"ท่านสามารถกรอก a-z A-Z 0-9 และอักขระพิเศษ ยกเว้น ' \" และ ;",
  "passwordInfo1":"แจ้งให้ทราบ:",
  "passwordInfo2":"- รหัสผ่านจะต้องมีความยาว 4-16 ตัวอักษร",
  "passwordInfo3":"- อนุญาตให้ใช้ตัวอักษร a-z, A-Z และตัวเลข 0-9 และอักขระพิเศษ",
  "passwordInfo4":"- อักขระพิเศษที่สามารถป้อนได้ ได้แก่:",
  "passwordInfo5":"   ! # $ % & ( ) * + , - . / : < = > ? @ [ \ ] ^ _ ` { | } ~",
  "username":"ชื่อผู้ใช้",
  "singIn":"เข้าสู่ระบบ",
  "email":"อีเมล",
  "password":"รหัสผ่าน",
  "firstName":"ชื่อ",
  "lastName":"นามสกุล",
  "birthday":"วันเกิด",
  "idCard":"เลขบัตรประชาชน",
  "passport":"พาสปอร์ต",
  "citizenId":"เลขบัตรประชาชน",
  "passportNo":"พาสปอร์ต",
  "register":"ลงทะเบียน",
  "mobileNo":"เบอร์โทร",
  "female":"หญิง",
  "male":"ชาย",
  "gender":"เพศ",
  "hMdOccupation":"นำเข้าข้อมูลอาชีพ",

  "nationality":"สัญชาติ",
  "vNasId":"กรุณากรอก nasId",
  "vNasIdPattern":"nasId ฟอร์แมตไม่ถูกต้อง",
  "vUsername":"กรุณากรอกชื่อผู้ใช้",
  "vMobileNo":"กรุณากรอกเบอร์โทร",
  "vPassword":"กรุณากรอกรหัสผ่าน",
  "vNewPassword":"กรุณากรอกรหัสผ่านใหม่",
  "vConfirmPassword":"กรุณากรอกยืนยันรหัสผ่าน",
  "vConfirmPasswordNotMatch":"รหัสผ่านใหม่และยืนยันรหัสผ่านไม่ตรงกัน",
  "vConfirmPasswordNotMatch2":"รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน",
  "vEmail":"กรุณากรอกอีเมล",
  "vUser": "กรุณากรอกผู้ใช้งาน",
  "vPackage": "กรุณากรอกชื่อแพ็กเกจ",
  "vIDcard_":"ข้อมูลบัตรประจำตัวประชาชนไม่ถูกต้อง",
  "vPassport_":"ข้อมูลพาสปอร์ตไม่ถูกต้อง ",
  "errPhoneRegex":"ข้อมูลโทรศัพท์ไม่ถูกต้อง",
  "vFirstName":"กรุณากรอกชื่อ",
  "vName":"กรุณากรอกชื่อ",
  "vLastName":"กรุณากรอกนามสกุล",
  "vBirthday":"กรุณากรอกวันเกิด",
  "vIdCard":"กรุณากรอกเลขบัตรประชาชน หรือ พาสปอร์ต",
  "vPassport":"กรุณากรอกเลขบัตรประชาชน หรือ พาสปอร์ต",
  "vGender":"กรุณาเลือกเพศ",
  "vMacAdd":"กรุณากรอกรหัสประจำเครื่องให้ถูกต้อง",
  "vMobile": "รูปแบบเบอร์โทรศัพท์มือถือไม่ถูกต้อง กรุณากรอกเบอร์โทรศัพท์มือถือให้ครบ 10 หลัก",
  "vCorEmail": "กรุณากรอกอีเมลให้ถูกต้อง",
  "vCitizen": "กรุณากรอกข้อมูลหมายเลขบัตรประชาชน / พาสปอร์ตให้ถูกต้อง",
  "vStatus":"กรุณากรอกสถานะสมาชิก",
  "vNumber":"กรุณากรอกข้อมูลเป็นตัวเลขเท่านั้น",
  "vActivated": "กรุณากรอก วัน/เวลา ที่ใช้งานครั้งแรก",
  "vActiveExpireDate": "กรุณากรอก วัน/เวลา หมดอายุ นับจากใช้งานครั้งแรก",
  "vNumCard": "กรุณากรอกจำนวนบัตร",




    "vTimeLimit": "กรุณากรอกข้อมูลจำกัดเวลา",
    "vDownload": "กรุณากรอกความเร็วดาวน์โหลด",
    "vUpload": "กรุณากรอกความเร็วอัปโหลด",
    "vValidDays": "กรุณากรอกจำนวนวันที่ใช้ได้หลังจาก login ครั้งแรก",
    "vValidMinutes": "กรุณากรอกจำนวนนาทีใช้ได้หลังจาก login ครั้งแรก",
    "vExpireDate": "กรุณาเลือก วัน/เดือน/ปี ที่บัตรนี้หมด",
    "vShowPrice": "กรุณากรอกราคาแสดงหน้าบัตร",
    "vRealPrice": "กรุณากรอกราคาคำนวณรายได้",
    "vCardColor": "กรุณากรอกแถบสีบนบัตร",
    "vTextColor": "กรุณากรอกสีตัวอักษรบนแถบสีของบัตร",
    "vIdleTimeout": "กรุณากรอกเวลาตัดออกจากระบบอัตโนมัติถ้าไม่มีการรับส่งข้อมูลอินเตอร์เน็ต",
    "vSimultaneousUse": "กรุณากรอกจำนวนผู้ใช้ที่ใช้บัตรใบเดียวกันได้พร้อมกัน",
    "vSimultaneousUseNum": "กรอกข้อมูลได้เฉพาะตัวเลข 1-9999",
    "vRandom": "กรุณากรอกค่าสุ่ม %",
    "vAdName": "กรุณากรอกชื่อ",
    "vRedirectUrl": "กรุณากรอกเปลี่ยนเส้นทาง URL ให้ถูกต้อง",
    "vInputPackage": "กรุณากรอกชื่อแพ็กเกจ",
    "vImportExcel": "กรุณาเลือกไฟล์ Excel ที่จะนำเข้า",
  "rememberMe":"จดจำชื่อผู้ใช้และรหัสผ่าน",
  "forgotPassword":"ลืมรหัสผ่าน?",
  "newPassword":"รหัสผ่านใหม่",
  "vOldPassword":"กรุณากรอกรหัสผ่านเดิม",
  "oldPassword":"รหัสผ่านเดิม",
  "confirmPassword":"ยืนยันรหัสผ่าน",
  "signIn":"เข้าสู่ระบบ",
  "loginForFree":"เข้าใช้งานฟรี",
  "back":"กลับ",
  "registerNow":"ลงทะเบียน",
  "registerText":"ลงทะเบียนเพื่อเข้าสู่ระบบ",
  "or":"หรือ",
  "welcome":"ยินดีต้อนรับ",
  "dateOfBirth":"วันเกิด",
  "loginWithFacebook":"เข้าสู่ระบบด้วย Facebook",
  "loginWithERegister":"เข้าสู่ระบบด้วยอีเมล (E-Register)",
  "vNotRobot":"กรุณาคลิกกล่องด้านบนเพื่อยืนยันว่าท่านไม่ใช่หุ่นยนต์",
  "editProfile":"ข้อมูลส่วนตัว",
  "changePassword":"เปลี่ยนรหัสผ่าน",
  "submit":"ยืนยัน",
  "savePassword":"บันทึกรหัสผ่าน",
  "save":"บันทึก",
  "cancel":"ยกเลิก",
  "usageHistory":"ข้อมูลการใช้งาน",
  "memberForgot":"เปลี่ยนรหัสผ่าน",
  "reverse":"คืนค่า",
  "resetPassword":"ตั้งค่ารหัสผ่านใหม่",
  "reset":"ตั้งค่ารหัสผ่านใหม่",
  "resetPasswordSub":"กรุณากรอกอีเมลของท่านเพื่อรับขั้นตอนการตั้งรหัสผ่านใหม่",
  "resetPasswordSub2":"กรุณากรอกชื่อผู้ใช้ของท่านเพื่อรับขั้นตอนการตั้งรหัสผ่านใหม่",
  "login":"เข้าสู่ระบบ",
  "name":"ชื่อผู้ใช้",
  "startDate":"วันที่เริ่มต้นใช้งาน",
  "validUntil":"ใช้งานได้ถึง",
  "remainingTime":"เวลาที่คงเหลือ",
  "on":"ข้อมูล ณ",
  "signOut":"ออกจากระบบ",
  "cardType":"ประเภทบัตร",
  "timeLimit": "เวลาที่ใช้ได้สูงสุด",
  "timeUsed": "เวลาที่ใช้ไป",
  "timeRemaining": "เวลาที่คงเหลือ",
  "startTime":"เริ่ม",
  "stopTime":"สิ้นสุด",
  "upload":"อัพ (Mb)",
  "download":"ดาวน์ (Mb)",
  "sessionTime":"เวลา",
  "usageDetails":"ข้อมูลการใช้งาน",
  "total":"รวม",
  "contactUs":"ติดต่อเรา",
  "lblStampLink":"คลิกเพื่อให้ผู้ใช้อีกเครื่องออกจากระบบ",
  "already":"ท่านไม่สามารถ login ได้เนื่องจาก login เครื่องอื่นอยู่",
  "warningChagepassword":"** การแก้ไขรหัสผ่านนี้จะมีผลต่อการ login <br/> ด้วย  email (e-register) เท่านั้น",
  "successfullyRegisteredAndLogged":"ลงทะเบียนและเข้าสู่ระบบสำเร็จแล้ว  ",
  "emailNotConfirmed":"อีเมลนี้ยังไม่ได้รับการยืนยัน ",
  "facebookLoginWith":"เข้าสู่ระบบด้วย",
  "chilliError":"ไม่สามารถเชื่อมต่อกับเครื่องแม่ข่ายได้ กรุณาลองใหม่อีกครั้ง",
  "tryAgain":"ลองอีกครั้ง",
  "otp":"ลงทะเบียน - ยืนยัน OTP",
  "otp_2":"ลืมรหัสผ่าน - ยืนยัน OTP",
  "continue":"ดําเนินการต่อ",
  "resendOtp":"รับรหัส OTP ใหม่",
  "mOtp1":"กรุณากรอก OTP ให้ครบ 6 ตัว",
  "mOtp2":"ท่านแล้ว SMS",
  "mOtp3":"หมายเลขอ้างอิง",
  "mOtp4":"OTP จะหมดอายุภายในเวลา",
  "smotp":"เบอร์สำหรับรับ OTP",
  "edit": "แก้ไข",
  "remarks": "หมายเหตุ",
  "remarks_otp": "ไม่สามารถปิดช่องกรอกเบอร์โทรได้ เนื่องจากท่านได้ทำการเลือกวิธียืนยันการลงทะเบียนเป็นแบบ OTP",
  "status-3": "แทน Downtime ที่มากกว่า 1 วัน",
  "status-2": "แทน Downtime ที่มากกว่า 10 นาที",
  "status-1": "แทน Downtime ที่มากกว่าหรือเท่ากับ 7 นาที",
  "status-0": "แทน Downtime ที่น้อยกว่า 7 นาที",
  "wifi-remarks":"หมายเหตุสถานะ Wifi",
  "wifi-status-3":"เกิดข้อผิดพลาดในการโหลดสถานะ Wifi กรุณาคลิกปุ่ม <i class=\"fa fa-repeat\" aria-hidden=\"true\"></i> เพื่อทำการโหลดใหม่",
  "auto_refresh": "รีเฟรชอัตโนมัติ",
  "customer_code": "รหัสลูกค้า",
  "building_name_th": "ชื่อสถานที่ (ไทย)",
  "building_name_en": "ชื่อสถานที่ (อังกฤษ)",
  "building_name_th2": "ชื่อสถานประกอบการ (ไทย)",
  "building_name_en2": "ชื่อสถานประกอบการ (อังกฤษ)",
  "servicePhone":"เบอร์โทรสถานที่",
  "service_phone": "บริการโทรศัพท์",
  "announce": "ประกาศ",
  "announce_expire": "ประกาศหมดอายุ",
  "social_login": "เปิดใช้งาน Social Login",
  "mobile_login": "เปิดใช้งาน Mobile Login",
  "package_social": "แพ็กเกจโซเชียล",
  "confirm_register_type": "ยืนยันประเภทการลงทะเบียน",
  "enable_login": "เปิดใช้งาน Login with %{social}",
  "enable_feature_redirect": "เปิดใช้งาน Feature Redirect",
  "add": "เพิ่ม",
  "close": "ปิด",
  "ok": "ตกลง",
  "url": "ยูอาร์แอล",
  "delete": "ลบ",
  "view": "ดูเพิ่มเติม",
  "redirect_title_popup": "%{action} การเปลี่ยนเส้นทาง",
  "service_type": "ประเภทบริการ",
  "logout": "ออกจากระบบ",
  "choose_location":"เลือกสถานที่",
  "register_alpha": "ลงทะเบียนใช้งาน Alpha Box",
  "customer_current": "ลูกค้าปัจจุบัน",
  "customer_new": "ลูกค้าใหม่",
  "radio_auto_login": "ล็อคอินอัตโนมัติจนกว่าฉันจะออกจากระบบ",
  "radio_save_user": "จดจำชื่อผู้ใช้",
  "radio_always_ask": "ถามชื่อผู้ใช้และรหัสผ่านของฉันเสมอ",
  "owner_name": "ชื่อ-นามสกุล",
  "owner_phone": "เบอร์โทรศัพท์มือถือ",
  "owner_user": "ตั้งชื่อบัญชีผู้ใช้งาน",
  "owner_pass": "ตั้งรหัสผ่าน",
  "acc_name": "เจ้าของบัญชี",
  "address": "ที่อยู่",
  "province": "จังหวัด",
  "district": "เขต/อำเภอ",
  "sub_district": "แขวง/ตำบล",
  "vAddress":"กรุณากรอกที่อยู่",
  "vProvince":"กรุณาเลือกจังหวัด",
  "vDistrict":"กรุณาเลือกเขต/อำเภอ",
  "vSubDistrict":"กรุณาเลือกแขวง/ตำบล",
  "zip_code": "รหัสไปรษณีย์",
  "vPostCode":"กรุณากรอกรหัสไปรษณีย์",
  "vServicePhone":"กรุณากรอกเบอร์โทรสถานที่",
  "nas_id": "nasId",
  "acc_info": "ข้อมูลบัญชีผู้ใช้",
  "msg_phone": "* เบอร์โทรศัพท์มีผลต่อการกู้คือบัญชีกรณีลืมรหัสผ่าน",
  "popup_user2": "ชื่อบัญชีผู้ใช้มีความยาวสูงสุด 64 อนุญาตให้ใช้ตัวอักษร a-z, A-Z และตัวเลข 0-9 เท่านั้น",
  "popup_user": "ชื่อบัญชีผู้ใช้มีความยาวสูงสุด 64 ตัวอักษร <br>- อนุญาตให้ใช้ตัวอักษร a-z, A-Z และตัวเลข 0-9 เท่านั้น",
  "popup_pass": "รหัสผ่านจะต้องมีความยาว 4-16 ตัวอักษร <br>- อนุญาตให้ใช้ตัวอักษร a-z, A-Z และตัวเลข 0-9 และอักขระพิเศษ <br>- อักขระพิเศษที่สามารถป้อนได้ ได้แก่: <br>! # $ % & ( ) * + , - . / : < = > ? @ [ ] ^ _ ` { | } ~",
  "next": "ถัดไป",
  "cr_back": "ย้อนกลับ",
  "location": "ตำแหน่งที่อยู่",
  "popup_nas_id": "ตัวอย่าง nasId เช่น XX-XX-XX-XX-XX-XX",
  "name_location":"ชื่อสถานที่",
  "vNameLocation":"กรุณาเลือกสถานที่",
  "changeTheme":"เปลี่ยน ธีม",
  "log":"บันทึกข้อมูล พรบ.คอมพิวเตอร์",
  "social-report":"รายงานโซเชียล",
  "login-report":"รายงานเข้าสู่ระบบ",
  "user_management":"จัดการผู้ใช้",
  "createUser":"สร้างบัญชีผู้ใช้",
  "locationInformation":'ข้อมูลสถานที่',
  "confirmRegistration":"ยืนยันการลงทะเบียน",
  "please_select":'กรุณาเลือก',
  "ChooseExistingLocation":"เลือกที่อยู่เดิม",
  "create_user_account": "สร้างบัญชีผู้ใช้",
  "location_title": "ข้อมูลสถานที่",
  "confirm_connection": "ยืนยันการลงทะเบียน",
  "background_image_system":"ใช้งานรูปภาพ พื้นหลัง ค่าตั้งต้นจากระบบ",
  "customize_background_yourself":"กำหนดรูปภาพ พื้นหลัง ด้วยตัวเอง",
  "logo_image_system":"ใช้งาน โลโก้ ค่าตั้งต้นจากระบบ" ,
  "customize_logo_yourself":"กำหนดรูปภาพ โลโก้ ด้วยตัวเอง",
  "preview":"ตัวอย่าง",
  "background":"พื้นหลัง",
  "logo":"โลโก้",
  "color":"ชุดสี",
  "format_preview":"คลิกเลือกรูปแบบ เพื่อดูภาพ ตัวอย่าง",
  "Upload10":"อัปโหลดรูปโลโก้ เพื่อดูภาพ ตัวอย่าง รูปภาพต้องการ 345 * 345 มีขนาดไฟล์ไม่เกิน 1MB",
  "UploadBg":"อัปโหลดรูปพื้นหลัง เพื่อดูภาพ ตัวอย่าง รูปภาพต้องการ 800 * 800 เป็นต้น และมีขนาดไฟล์ไม่เกิน 1MB",
  "warningHttp": "*** เพื่อความครบถ้วนระบบได้เติม http:// ไว้ด้านหน้า URL ที่ท่านพิมพ์ไว้ หากการเปลี่ยน URL ของท่านเป็น https:// ท่านสามารถแก้ไขเพิ่มเติมได้เองในช่องการเปลี่ยนเส้นทาง URL",
  "Choose_logo_image":"เลือกรูปภาพโลโก้",
  "Choose_background_image":"เลือกรูปภาพพื้นหลัง",
  "add_address": "เพิ่มที่อยู่ใหม่",
  "errm1":"กรุณากรอกเบอร์โทรศัพท์มือถือ",
  "errm2":"กรุณากรอกเบอร์โทรศัพท์มือถือให้ครบ 10 หลัก",
  "errm3":"รูปแบบเบอร์โทรศัพท์มือถือไม่ถูกต้อง  กรุณากรอกเบอร์โทรศัพท์มือถือให้ครบ 10 หลัก",
  "errm4":"รูปแบบเบอร์โทรสถานที่ไม่ถูกต้อง",
  "vServicePhone":'กรุณากรอกเบอร์โทรสถานที่',
  "vAddress":"กรุณากรอกที่อยู่",
  "chooseBuilding":"เลือกอาคาร",
  "SendOtp":"ส่งรหัส OTP",
  "ReferenceNo":"รหัสอ้างอิง",
  "search":"ค้นหา",
  "search2":"ช่วงเวลาที่ต้องการค้นหา",
  "detail":"รายละเอียดข้อมูล",
  "socialReport":"รายงานโซเชียล",
  "export":"Export",
  'download':'ดาวน์โหลด',
  'historicalData':'ข้อมูลย้อนหลัง',
  'from':'ตั้งแต่',
  'to':'ถึง',
  'username2':'ชื่อบัญชีผู้ใช้',
  'xusername':'*สามารถเปลี่ยนรหัสผ่านได้เฉพาะชื่อผู้ใช้ที่เป็นเจ้าของสถานที่เท่านั้น',
  "regis_h1":'กรอกข้อมูล ตาม พ.ร.บ. กำหนด',
  "regis_h2":'ลงทะเบียนและยืนยันตัวตนทาง email ครั้งแรกเพียงครั้งเดียวเท่านั้น',
  "regis_h3":'ลงทะเบียนครั้งแรกเพียงครั้งเดียวเท่านั้น',
  'advertisement-management':"การจัดการโฆษณา",
  "advertisement-1": "โฆษณา 1",
  "advertisement-2": "โฆษณา 2",
  "advertisement-3": "โฆษณา 3",
  "advertisement-4": "โฆษณา 4",
  "adName": "ชื่อ",
  "redirectUrl": "Redirect Url",
  "random": "Random %",
  "package": "แพ็กเกจอินเทอร์เน็ต",
  "export-excel": "ดาวน์โหลด",
  "facebookLikePage":"เปิดใช้งาน Facebook Like Page",
  "online-user": "รายชื่อผู้ที่ใช้งานอยู่ตอนนี้",
  "member-manager": "จัดการสมาชิก",
  "revenue-report": "รายงานรายได้",
  "card-stock": "รายงานจำนวนบัตร",
  "ChangeWifiName": "เปลี่ยนชื่อ Wifi",
  "WifiName":"ชื่อ Wifi (SSID)",
  "BoxStatus":"สถานะของกล่อง",
  'DatetimeStamp':"วันเวลาที่บันทึก",
  "WifiStatus":"สถานะ Wifi",
  "vWifiName":"กรุณากรอกชื่อ Wifi",
  "browser": "เบราว์เซอร์",
  "deviceCategory": "ประเภทอุปกรณ์ที่ใช้งาน",
  "deviceLanguage": "ภาษา",
  "os": "ระบบปฎิบัติการ",
  "brand": "ยี่ห้อ",
  "model": "รุ่น",
  "hour": "ช่วงเวลาที่ใช้งาน(นาฬิกา)",
  "selectedLanguage": "ภาษาที่ใช้",
  "users": "จำนวนผู้ใช้งาน",
  "timePreiod":'ช่วงเวลา',
  "noData":"ไม่พบข้อมูล",
  "noFacebookId":"กรุณากรอกลิงก์ของเพจ facebook",
  "noFacebookId2":"ไม่สามารถค้นหาไอดีของเพจ facebook ได้ กรุณากรอกลิงก์ของเพจ facebook ที่ถูกต้อง",
  "DENIED":"ไม่มีสิทธิ์เข้าถึงส่วนนี้",
  "ads-wx":"** อัปโหลดรูป เพื่อดูภาพตัวอย่าง รูปภาพต้องการ 300 * 250 มีขนาดไฟล์ไม่เกิน 1MB",
  "ads-y":"** อัปโหลดรูป เพื่อดูภาพตัวอย่าง รูปภาพต้องการ 330 * 480 เป็นต้น และมีขนาดไฟล์ไม่เกิน 1MB",
  "wifiActivated":"ระบบได้ทำการเปิดใช้งาน Wifi เรียบร้อยแล้ว",
  "wifiDisabled":"ระบบได้ทำการปิดใช้งาน Wifi เรียบร้อยแล้ว",
  "country":"ประเทศ",
  "city":"จังหวัด",
  "card_gen": "สร้าง/พิมพ์ บัตร",
  "card_logo": "โลโก้บัตร",
  "card_manager": "จัดการบัตร",
  "remarkUnLimit":"ไม่จำกัดให้ใส่ 0",
  "textTotal": "ผลลัพธ์ %{resultCount} รายการ จากทั้งหมด %{totalCount} รายการ",
  "date":"วันที่",
  "version_history":"ประวัติ เวอร์ชั่น",
  "occupation":"อาชีพ",
  "occupation2":"อาชีพ",
  "add_occupation":"ส่งคำขอ ขอเพิ่มอาชีพ",
  "advertisement_report":"รายงานโฆษณา",
  "Upload11":"อัปโหลดรูปโลโก้ เพื่อดูภาพตัวอย่าง รูปภาพต้องการ 200 * 200 มีขนาดไฟล์ไม่เกิน 1MB",
  "role_management": "จัดการสิทธิ์",
  "import":"นำเข้า",
  "theme":"ธีม",
  "confirmEmailExpireMins":"กำหนดเวลาใช้อินเตอร์เน็ตให้ผู้ใช้งานเพื่อยืนยันอีเมล(นาที)",
  "vConfirmEmailExpireMins1":"กรุณากรอกกำหนดเวลาใช้อินเตอร์เน็ตให้ผู้ใช้งานเพื่อยืนยันอีเมล(นาที)",
  "vConfirmEmailExpireMins2":"กรอกตัวเลขเท่านั้น",
  "vRoleName":"กรุณากรอกชื่อ Role",
  "enable_advertisement":"เปิดใช้งาน ระบบแสดงโฆษณา"
}

export default TH
