import { config as configDev}  from '../config/dev';
import { config as configLocal}  from '../config/local';
import { config as configProd}  from '../config/prod';
import uuid from "uuidv4" ;
class Config {
  config = false;
  getConfig(){
        if (this.config) {
          return (this.config);
        }

        if(process.env.REACT_APP_ENV === "local"){
          this.config = {...configLocal,x_transaction_id:uuid()}
          return (this.config);
        }else if(process.env.REACT_APP_ENV === "dev"){
          this.config = {...configDev,x_transaction_id:uuid(),}
          return (this.config);
        }else{
          this.config = {...configProd,x_transaction_id:uuid(),}
          return (this.config);
        }
  }
}

export default new Config();
