// import config from 'config';
import { authHeader } from '../_helpers';
import Config from './app.config';
// import axios from 'axios';
import Request from './request.service';

// console.log("Config",)
let x_transaction_id = '';
let config = Config.getConfig();
export const userService = {
    login,
    logout,
    getAll,
    custgroupList,
    permissionLogin
};

function login(username, password ,flag = 0) {
    let type = 'AB';
    if((window.location.host && window.location.host.indexOf("szadmin.")> -1 )){
        type = 'SZ'
    }
    return Request.postLogin(`/api/szadmin/login`, {user:username, pass:password,flag:flag,type :type })
    .then(res => {
      return res;
    }).catch((e)=>{
      console.log(e)
      return e;
    })
}

function   getLocale() {
    let languageCode = "TH";
        try {
          var haslocalStorage = !!localStorage;
          if (haslocalStorage) {

          if (localStorage.getItem("locale")) {
                languageCode = localStorage.getItem("locale") || "EN";
              }
            }
        }
        catch (e) {

        }
    return languageCode;
  }

function custgroupList(token) {
  return Request.get(`/api/szadmin/custgroupList`,{flag:0}, {
      language: getLocale(),
      "x-access-id":token,
      // "x-transaction-id":  x_transaction_id,
      // ...headers
  })
  // .then(response => response.data)
  .then(res => {
    return res;
  }).catch((e)=>{
    console.log(e)
    return e;
  })
}

function permissionLogin(data) {
  console.log("permissionLogin",data)
  return Request.get(`/api/szadmin/permissionLogin`,{},{
      language: getLocale(),
      "x-access-id":data.token,
      // "x-transaction-id":  x_transaction_id,
      "custGroupId":data.custgroupId,
      "roleId":data.roleId,
      // ...headers
    }
  )
  // .then(response => response.data)
  .then(res => {
    return res;
  }).catch((e)=>{
    console.log(e)
    return e;
  })
}

function logout() {
    // remove user from local storage to log user out
    try {
      let user = JSON.parse(localStorage.getItem('user-data'));
      return Request.get(`/api/szadmin/logout`,{}, {
          language: getLocale(),
          "x-access-id":user.token,
          // "x-transaction-id":  x_transaction_id,
        }
      )
      .then(response => response.data)
      .then(res => {
        localStorage.removeItem('user-data');
        return res;
      }).catch((e)=>{
        console.log(e)
        localStorage.removeItem('user-data');
        return e;
      })
      } catch (error) {
        localStorage.removeItem('user-data');
      }


}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
