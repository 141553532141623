import { localeConstants } from '../_constants';

let localeData = localStorage.getItem('locale')?localStorage.getItem('locale'):'TH';
const initialState = localeData ;

export function locale(state = initialState, action) {
  switch (action.type) {
    case localeConstants.LOCAL_UPDATE:
      return  action.data;
    default:
      return state
  }
}
