export let config = {
  "name":"prod",
  "app_api": "//admin-api.alphabox.app",
  "chilli_host": "192.168.182.1",
  "chilli_hosts":"chilli.smartzoneonline.com",
  "chilli_port": "3990",
  "sentry":"https://22b16ec3d990402ba3a83f3a9850e6e7@sentry.io/1513174",
  "reCaptcha":"6LdS27gUAAAAAMx0D6yDmILrk1bvjnl6JAR4NQ1z",
  "fbAppId":"1673614886038454",
  "fbClientSecret":"c91e3a9c0080930d95965c4e15eb3a8e",
  "gAnalytics":"UA-146237040-2"
}
