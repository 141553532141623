export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('user-data'));

  if (user && user.token) {
      return { 'Authorization': 'Bearer ' + user.token };
  } else {
      return {};
  }
}


export function isAuth(props) {
  let loaded = false
  if(props.user && props.user.token){
    loaded = true;
  }else{
    props.history.push("/login")
  }
  return loaded;
}
