import React, { Component } from 'react';
import {  Route, Switch, BrowserRouter,Redirect  } from 'react-router-dom';
import { BrowserRouter as HashRouter } from 'react-router-dom'
import { connect } from 'react-redux';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'video-react/dist/video-react.css';
import { userActions,menuActions } from './_actions';
// import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Request from "./_services/request.service";
import i18n from "./i18n";
import routes from "./routes";

import {Helmet} from "react-helmet";
import history from './history';
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout/DefaultLayout'));
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
// Pages
const Home = React.lazy(() => import('./views/Pages/Home/Home'));
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const Register = React.lazy(() => import('./views/Pages/Register/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'));
const ForgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword/ForgotPassword'));

const CustomTheme = React.lazy(() => import('./views/CustomTheme/CustomTheme'));


// const CustgroupList = React.lazy(() => import('./views/CustgroupList/CustgroupList'));


class AppRouter extends Component {
  componentWillReceiveProps(nextProps) {
    if(nextProps.menu.items && nextProps.menu.error){
      nextProps.history.push("/")
    }else if (nextProps.menu != this.props.menu){
      // alert("xx")

      let firstData = null
      if( nextProps.menu.length > 0){
          firstData = nextProps.menu[0].codeName
          if(nextProps.menu[0].pageList && nextProps.menu[0].pageList.length > 0){
                firstData = nextProps.menu[0].pageList[0].codeName
          }
        }
      let _routes = routes.find(r=>r.code === firstData)


       console.log("_routes");
      if(_routes && _routes.path && nextProps.history.location.pathname === '/custgroup-list'){
        nextProps.history.push( _routes.path)
      }
    }
  }

  render() {
    console.log("AppRouter",this.props.user,this.props.users)
    return <>
    {!(this.props.menu.items && this.props.menu.error) > 0?
      <DefaultLayout  {...this.props}/>
    :null}
    </>
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { users, authentication,menu } = state;
  const { user } = authentication;
  return {
      user,
      users,
      loggingIn,
      menu
  };
}
const AppRouterPage =  connect(mapStateToProps)(AppRouter);



class App extends Component {

  constructor(props) {
    super(props);



    console.log("v 1.0.1");
    const parsed = queryString.parse(window.location.search);
    let register =  parsed.register

    if((window.location.host && window.location.host.indexOf("alphabox.")> -1 )|| parsed.apb){
        register = true
    }

    if(window.location.pathname === '/register' || window.location.pathname === '/register/box' ){
      register = true
    }



    this.state = {
      loaded: false,
      loading: false,
      register:register
    }


    if(props.menu.length === 0){
      setTimeout(() => {
        this.roleUserMenu(props)
      }, 50);
    }
    console.log("apb",register);
    localStorage.setItem("apb",register)
  }


  componentDidMount() {
    this.initData()



  }

  initData(){
    const parsed = queryString.parse(window.location.search);
    // console.log("this.props.match",parsed,window.location.search)

    if(parsed.nasId){
      this.checknasId({
        nasId:parsed.nasId
      })
    }else{
      this.setState({loaded:true})
    }
  }


  checknasId(data) {
    this.setState({
      loaded:true,
      nasId:data && data.nasId? data.nasId :null
    })
  }


roleUserMenu(nextProps){
  if( nextProps.user && nextProps.user.roleId){
    this.getMenu(nextProps.user)
  }
}



  getMenu(user){
    this.setState({loading:true},()=>{
      setTimeout(()=>{
        this.setState({loading:false})
        const { dispatch } = this.props;
        dispatch(menuActions.getMenu(user));
      },50)
    });

  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.user && nextProps.user.custgroupId != (this.props.user?this.props.user.custgroupId:null)){
      this.roleUserMenu(nextProps)
    }
  }


  render() {
    return (
      <>
      {
        this.state.register?<>
        <Helmet>
        <title>Alpha Box Administrator Backend</title>
        <link rel="shortcut icon"  href={require('./assets/favicon2.ico')}  />
        </Helmet>

          </>:<>
          <Helmet>
          <title>SmartZone™  Administrator Backend</title>
          <link rel="shortcut icon"  href={require('./assets/favicon.ico')}  />
          </Helmet>
        </>
      }

      <HashRouter history={history}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/register" name="Home Page" render={props => <Home {...props}/>}/>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register/box" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/forgot-password" name="Page 404" render={props => <ForgotPassword {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/custom-theme" name="Custom Theme" render={props => <CustomTheme {...props}/>} />

              {!this.state.loading?<>
              {
               this.props.user && this.props.user.token?
                <>
                  <Route path="/" name="Home" render={props => <AppRouterPage {...props}/>} />
                </>
                :<>
                {
                  this.state.loaded?<>
                    {
                       this.state.nasId?
                       <Redirect to={{pathname:'/register' ,search:"nasId="+this.state.nasId }} />
                       :
                       <Redirect to='/login' />
                    }
                  </>:null
                }
                </>
              }
              </>
              :null}
            </Switch>
          </React.Suspense>
      </HashRouter>
      </>
    );
  }
}


export default connect(mapStateToProps)((App));
