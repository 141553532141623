import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';
import i18nDictionary from '../i18n'
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n'


const loggerMiddleware = createLogger();
const _store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware,
    loggerMiddleware
  )
);
syncTranslationWithStore(_store)
_store.dispatch(loadTranslations(i18nDictionary))

let localeData = localStorage.getItem('locale')?localStorage.getItem('locale'):'TH';
_store.dispatch(setLocale(localeData))
export const store = _store;
