import { menuConstants } from '../_constants';

export function menu(state = [], action) {
  switch (action.type) {
    case menuConstants.GETALL_SUCCESS:
      return action.menu;
    case menuConstants.GETALL_FAILURE:
      return {error:true};
    default:
      return state
  }
}
