import AppConfig  from './app.config';
import history from '../history';
import { userActions,menuActions } from '../_actions';
import { connect } from 'react-redux';
import { store } from '../_helpers';
const axios = require('axios');
const uuid = require("uuidv4");
// const action = type => store.dispatch({type})
class Request {
  api = '';
  x_transaction_id = '';
  userFlag = localStorage.getItem('user-data') ? JSON.parse(localStorage.getItem('user-data')) : {};
  constructor() {
              console.log("this>>>>",this);
              // console.log('userFlag :: ', this.userFlag.loginAlphaFlag);
    this.getConfig();
  }

  getConfig() {
    let config = AppConfig.getConfig()
      this.api = config.app_api;
      this.x_transaction_id = config.x_transaction_id;
  }

  get(url, data = {}, headers = {},timeout = 15000) {
    console.log("get:",url,data,headers);
    let loginAlphaFlag =  null
    try {
      loginAlphaFlag = localStorage.getItem('user-data') ?
                JSON.parse(localStorage.getItem('user-data')).loginAlphaFlag : 0
    } catch (e) {

    }
    return axios
      .get(this.api + url, {
        params: data,
        timeout: timeout,
        headers: {
          language: this.getLocale(),
          "x-transaction-id":  this.x_transaction_id,
          "loginAlphaFlag": loginAlphaFlag,
          ...headers
        }
      })
      .then(response => response.data)
      .then(
        data => {
          if(data.resultCode === "40101" || data.resultCode === "40100" || data.resultCode === "40104" ){
            store.dispatch(userActions.logout())
            return data
          }
          return data;
        },
        err => {
          console.log(err);
          return false;
        }
      );
  }

  getOther(url, data = {}, headers = {}) {
    console.log("get:",url,data,headers);

    let loginAlphaFlag =  null
    try {
      loginAlphaFlag = localStorage.getItem('user-data') ?
                JSON.parse(localStorage.getItem('user-data')).loginAlphaFlag : 0
    } catch (e) {

    }

    return axios
      .get(url, {
        params: data,
        timeout: 15000,
        headers: {
          "loginAlphaFlag": loginAlphaFlag,
          ...headers
        }
      })
      .then(response => response.data)
      .then(
        data => {
          console.log(data);
          return data;
        },
        err => {
          console.log(err);
          return false;
        }
      );
  }

  postFile(url, data = {}, headers = {},timeout = 15000) {
    console.log("post:",this.api + url);

    let loginAlphaFlag =  null
    try {
      loginAlphaFlag = localStorage.getItem('user-data') ?
                JSON.parse(localStorage.getItem('user-data')).loginAlphaFlag : 0
    } catch (e) {

    }

    return axios
      .post(url, data, {
        timeout: timeout,
        headers: {
          'Content-Type': 'multipart/form-data',
          "loginAlphaFlag": loginAlphaFlag,
          ...headers
        }
      })
      .then(response => response.data)
      .then(
        data => {
          return data;
        },
        err => {
          console.log(err);
          return false;
        }
      );
  }

  postLogin(url, data = {}, headers = {},timeout = 15000) {
    console.log("post:",this.api + url);

    let loginAlphaFlag =  null
    try {
      loginAlphaFlag = localStorage.getItem('user-data') ?
                JSON.parse(localStorage.getItem('user-data')).loginAlphaFlag : 0
    } catch (e) {

    }

    return axios
      .post(this.api + url, data, {
        timeout: timeout,
        headers: {
          language: this.getLocale(),
          "x-transaction-id":  this.x_transaction_id,
          ...headers
        }
      })
      .then(response => response.data)
      .then(
        data => {
          console.log(data);
          if(data.resultCode === "40101" || data.resultCode === "40100" || data.resultCode === "40104" ){
            store.dispatch(userActions.logout())
            return data
          }
          return data;
        },
        err => {
          console.log(err);
          return false;
        }
      );
  }

  post(url, data = {}, headers = {},timeout = 15000) {
    console.log("post:",this.api + url);

    let loginAlphaFlag =  null
    try {
      loginAlphaFlag = localStorage.getItem('user-data') ?
                JSON.parse(localStorage.getItem('user-data')).loginAlphaFlag : 0
    } catch (e) {

    }
    return axios
      .post(this.api + url, data, {
        timeout: timeout,
        headers: {
          language: this.getLocale(),
          "x-transaction-id":  this.x_transaction_id,
          "loginAlphaFlag": loginAlphaFlag,
          ...headers
        }
      })
      .then(response => response.data)
      .then(
        data => {
          console.log(data);
          if(data.resultCode === "40101" || data.resultCode === "40100" || data.resultCode === "40104" ){
            store.dispatch(userActions.logout())
            return data
          }
          return data;
        },
        err => {
          console.log(err);
          return false;
        }
      );
  }

  getLocale() {
    let languageCode = "TH";
        try {
          var haslocalStorage = !!localStorage;
          if (haslocalStorage) {
          if (localStorage.getItem("locale")) {
                languageCode = localStorage.getItem("locale") || "EN";
              }
            }
        }
        catch (e) {

        }
    return languageCode;
  }


}
// let request =  new Request();
// let request = connect()(Request);
// let connectRequest = connect({})(Request);
// console.log("connectRequest",connectRequest());
export default  new Request();
