import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { menu } from './menu.reducer';
import { locale } from './locale.reducer';



const rootReducer = combineReducers({
  i18n: i18nReducer,
  authentication,
  users,
  alert,
  menu,
  locale
});

export default rootReducer;
